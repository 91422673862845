import { LogLevel } from '@waitroom/logger';
import { STORAGE_GENERAL, UserBasic } from '@waitroom/models';
import { createSelectors } from '@waitroom/state';
import { omit } from '@waitroom/utils';
import type { LogLevel as StreamLogLevel } from 'livekit-client';
import { create } from 'zustand';
import { envs } from '../services/envs';
import { storageService } from '../services/storage';

export type GeneralSettings = {
  deleteRequests: Dict<boolean> | undefined;
  hostsOnboarded: Dict<boolean> | undefined;
  hideBookDemo: boolean | undefined;
  hideWelcomeToDashboard: boolean | undefined;
  showChecklist: boolean | undefined; // TODO: this flag doesn't need to be persisted
  onboarding: UserBasic['onboarding'] | undefined;
  hideOnboardingPopovers: boolean | undefined;
  hideGenerateAIFeed: boolean | undefined;
  continueInMobWeb: string | undefined;
  debug:
    | {
        logEnabled?: boolean;
        logLevel?: LogLevel;
        streamLogLevel?: StreamLogLevel;
      }
    | undefined;
};

const storageState = storageService.getParsed<GeneralSettings>(STORAGE_GENERAL);
export const initialState: GeneralSettings = {
  deleteRequests: undefined,
  hostsOnboarded: undefined,
  hideBookDemo: undefined,
  hideWelcomeToDashboard: undefined,
  showChecklist: false,
  hideOnboardingPopovers: undefined,
  hideGenerateAIFeed: undefined,
  continueInMobWeb: undefined,
  onboarding: undefined,
  ...storageState,
  debug: {
    ...storageState?.debug,
    logLevel: (Number(storageState?.debug?.logLevel ?? envs.VITE_LOG_LEVEL ?? 0) ?? 1) as LogLevel,
    streamLogLevel: Number(storageState?.debug?.streamLogLevel ?? 4),
  },
};

const useGeneralSettingsStoreBase = create<GeneralSettings>()(() => initialState);

export const useGeneralSettingsStore = createSelectors(useGeneralSettingsStoreBase);

// options to remove from storage - improve how we handle this (? zod)
const removeKeys = ['completedChecklist'];

export const updateGeneralSettings = (
  update: GeneralSettings | ((prev: GeneralSettings) => GeneralSettings),
) => {
  useGeneralSettingsStoreBase.setState((prev) => {
    const updated = typeof update === 'function' ? update(prev) : update;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    storageService.set(STORAGE_GENERAL, omit(updated, removeKeys as any));
    return updated;
  });
};

export const mergeGeneralSettings = (settings: Partial<GeneralSettings>) => {
  useGeneralSettingsStoreBase.setState((prev) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updated = omit({ ...prev, ...settings }, removeKeys as any);
    storageService.set(STORAGE_GENERAL, updated);
    return updated;
  });
};

export const updateDebugSettings = (updated: Partial<GeneralSettings['debug']>) => {
  updateGeneralSettings((prev) => ({
    ...prev,
    debug: {
      ...prev.debug,
      ...updated,
    },
  }));
};
