import { SessionAccessRuleStatus as q } from "@waitroom/models";
import { buildSuccessRequestResponse as V, aiApiService as L, calendarApiService as te, sessionApiService as d, cmsApiService as W, integrationsApiService as Y, meetingMemoryApiService as H, recordingApiService as j, sessionAccessApiService as D, userApiService as b, authApiService as se, subscriptionPlanApiService as ne, tokenApiService as re, userMeetingTypesService as oe } from "@waitroom/common-api";
import { useQueryClient as z, useQuery as J, useInfiniteQuery as ae, QueryObserver as w } from "@tanstack/react-query";
import { braidService as ie } from "@waitroom/braid";
import { applyPatch as ue, deepClone as ye } from "fast-json-patch";
import { useEffect as l, useMemo as Ee, useRef as _e, useState as x, useCallback as ge } from "react";
const X = (e) => typeof e == "function", F = ({
  client: e,
  key: t,
  fn: s,
  skipEmpty: n
}) => {
  const r = e.getQueryData(t);
  n && r === void 0 || e.setQueryData(t, s(r));
}, v = (e) => F({
  ...e,
  fn: (t) => ({
    code: 200,
    success: !0,
    ...t,
    data: X(e.dataOrFn) ? e.dataOrFn(t == null ? void 0 : t.data) : e.dataOrFn
  })
}), Se = {
  update: v,
  addToArray: (e) => v({
    ...e,
    dataOrFn: (t) => [
      ...Array.isArray(e.data) ? e.data : [e.data],
      ...t || []
    ]
  }),
  updateInArray: ({
    data: e,
    keyId: t,
    ...s
  }) => v({
    ...s,
    dataOrFn: (n) => n == null ? void 0 : n.map((r) => r[t] === e[t] ? e : r)
  }),
  removeFromArray: ({
    id: e,
    keyId: t,
    ...s
  }) => v({
    ...s,
    dataOrFn: (n) => n == null ? void 0 : n.filter((r) => r[t] !== e)
  }),
  addToPages: (e) => F({
    ...e,
    fn: (t) => {
      var n, r, o;
      const s = {
        pageParams: [...(t == null ? void 0 : t.pageParams) || []],
        pages: [...(t == null ? void 0 : t.pages) || []]
      };
      return s.pages[0] = {
        ...s.pages[0],
        data: {
          code: 200,
          success: !0,
          ...(n = s.pages[0]) == null ? void 0 : n.data,
          data: e.updateFn((o = (r = s.pages[0]) == null ? void 0 : r.data) == null ? void 0 : o.data)
        }
      }, s;
    }
  }),
  updateInPages: (e) => F({
    ...e,
    fn: (t) => {
      var n, r;
      if (!t || !((n = t.pages) != null && n.length)) return t;
      const s = {
        pageParams: [...t.pageParams],
        pages: [...t.pages]
      };
      for (let o = 0; o < s.pages.length; o++) {
        const a = (r = s.pages[o]) == null ? void 0 : r.data, i = a == null ? void 0 : a.data;
        if (!i) continue;
        const y = e.updateFn(i);
        if (a !== y && (s.pages[o] = {
          ...s.pages[o],
          data: {
            ...a,
            data: y
          }
        }, e.stopOnChange))
          break;
      }
      return s;
    }
  })
}, ce = (e) => F({
  ...e,
  fn: (t) => {
    var s;
    return {
      code: 200,
      success: !0,
      ...t,
      data: {
        code: 200,
        success: !0,
        ...t == null ? void 0 : t.data,
        data: X(e.dataOrFn) ? e.dataOrFn((s = t == null ? void 0 : t.data) == null ? void 0 : s.data) : e.dataOrFn
      }
    };
  }
}), me = {
  update: ce
}, m = {
  update: F,
  request: Se,
  apiRequest: me
}, k = "private", Z = "calendarEvent", Ce = "cmsPage", pe = "cmsPages", Rt = "currentUser", Ae = "dashboardPastSessions", le = "dashboardSessions", Ot = "featuredSessions", ft = "integrationsConnections", Kt = "integrationsDirectURL", Tt = "integrationsGetAction", de = "integrationsProviders", Re = "meetingMemoryMessages", Pt = "meetingMemorySuggestions", Oe = "meetingMemoryThread", fe = "meetingMemoryThreads", Ke = "paymentDetails", Te = "recording", qt = "recordingStatus", Ft = "session", Pe = "session-v2", Dt = "sessionAccessRule", G = "sessionAccessRules", qe = "sessionAiFeed", Fe = "sessionEndSummary", De = "sessionEpisodes", Mt = "sessionPendingRulesStats", Me = "sessionPresence", h = "sessionRequestAccess", vt = "sessionViewers", ve = "sessionCards", Ne = "streamToken", Qe = "listeningModeSpeakers", Ye = "subscriptionPlans", He = "subscriptionReceipts", be = "user", Be = "integrationsGetByOwnerSessionRecurrence", Ge = "userMeetingTypes-v2", Nt = "branch", Qt = "salesforceProvider", Yt = "salesforceGetBindingById", Ht = "salesforceGetAccountById", bt = "salesforceGetOpportunityById", Bt = "salesforceListBindableRecordsAsOptions", Gt = "salesforceGetContacts", It = "salesforceGetLeadById", Lt = "hubspotProvider", kt = "hubspotGetBindingById", Ie = "hubspotGetCompanyById", Ut = "hubspotGetDealById", $t = "hubspotListBindableRecordsAsOptions", wt = "hubspotGetContacts", xt = "hubspotGetContactById", Vt = (e, t) => [k, ...e, t], N = (e, t = "") => [
  qe,
  e,
  t
], I = (e, t = "") => [
  h,
  e,
  t,
  q.IN_REVIEW
], Le = (e, t, s) => [h, e, t, s || ""], ke = (e, t, s) => [Pe, e, t || "", s || ""], Ue = (e, t = "") => [
  Me,
  e,
  t
], $e = (e) => [be, e], U = (e) => [
  fe,
  e
], Wt = (e) => [Oe, e], T = (e) => [
  Re,
  e
], jt = (e, t) => [
  Qe,
  e,
  t
], we = (e, t) => [
  Fe,
  e,
  t
], xe = {
  add: ({ client: e, data: t, sessionId: s, recurrenceId: n }) => {
    t && m.request.addToArray({
      client: e,
      key: N(s, n),
      data: [t]
    });
  },
  update: ({ client: e, data: t, sessionId: s, recurrenceId: n }) => {
    t && m.request.update({
      client: e,
      key: N(s, n),
      dataOrFn: (r) => r ? (r.messages = r.messages.map((o) => o.id === t.id ? t : o), r) : { messages: [t], totalSummaryCount: 1 }
    });
  },
  remove: ({ client: e, sessionId: t, recurrenceId: s, id: n }) => {
    m.request.removeFromArray({
      client: e,
      key: N(t, s),
      id: n,
      keyId: "id"
    });
  }
}, zt = {
  ...xe
}, Ve = {
  addMessage: ({ client: e, data: t, id: s }) => {
    t && m.apiRequest.update({
      client: e,
      key: T(s),
      dataOrFn: (n) => ({
        hasMore: !1,
        ...n,
        messages: [...(n == null ? void 0 : n.messages) || [], t],
        total: ((n == null ? void 0 : n.total) || 0) + 1
      })
    });
  },
  updateMessage: ({ client: e, data: t, id: s }) => {
    t && m.apiRequest.update({
      client: e,
      key: T(s),
      dataOrFn: (n) => n != null && n.messages ? {
        ...n,
        messages: n.messages.map((r) => r.id === t.id ? { ...r, ...t } : r)
      } : n
    });
  },
  updateLastAiMessage: ({ client: e, data: t, id: s }) => {
    t && m.apiRequest.update({
      client: e,
      key: T(s),
      dataOrFn: (n) => {
        var y;
        const r = n && {
          ...n,
          messages: n.messages ? [...n.messages] : void 0
        };
        if (!(r != null && r.messages)) return n;
        const o = r.messages.findLastIndex(
          (c) => (!t.id || c.id === t.id) && c.role === "ai"
        );
        if (o === -1) return n;
        const a = r.messages[o], i = { ...a, ...t, id: t.id || a.id };
        return t.content && (i.content = `${a.content || ""}${t.content}`), t.todo && (i.todo = `${a.todo || ""}${t.todo}`), t.progress && (i.progress = `${a.progress || ""}${t.progress}`), r.messages[o] = i, !((y = i.content) != null && y.length) && t.isComplete && r.messages.splice(o, 1), r;
      }
    });
  },
  removeMessage: ({ client: e, id: t, messageId: s }) => {
    m.apiRequest.update({
      client: e,
      key: T(t),
      dataOrFn: (n) => {
        var a;
        if (!(n != null && n.messages)) return n;
        const r = n.messages.length, o = (a = n.messages) == null ? void 0 : a.filter((i) => i.id !== s);
        return {
          ...n,
          messages: o,
          total: Math.max((n.total || 0) - (r !== o.length ? 1 : 0), 0)
        };
      }
    });
  },
  removeLastMessage: ({ client: e, id: t }) => {
    m.apiRequest.update({
      client: e,
      key: T(t),
      dataOrFn: (s) => s != null && s.messages ? {
        ...s,
        messages: s.messages.slice(0, -1),
        total: Math.max((s.total || 0) - 1, 0)
      } : s
    });
  },
  removeThread: ({ client: e, id: t, userId: s }) => {
    m.apiRequest.update({
      client: e,
      key: U(s),
      dataOrFn: (n) => {
        var a;
        if (!(n != null && n.threads)) return n;
        const r = n.threads.length, o = (a = n.threads) == null ? void 0 : a.filter((i) => i.id !== t);
        return {
          ...n,
          threads: o,
          total: Math.max((n.total || 0) - (r !== o.length ? 1 : 0), 0)
        };
      }
    });
  }
}, Jt = Ve;
let R;
const Xt = (e) => {
  R = e.logger;
}, We = (e) => {
  var t;
  return (t = e == null ? void 0 : e.data) == null ? void 0 : t.data;
}, Zt = (e) => We(e.data), ht = (e, t = 0) => {
  var s, n, r;
  return (r = (n = (s = e.data) == null ? void 0 : s.pages[t]) == null ? void 0 : n.data) == null ? void 0 : r.data;
}, je = (e, t, s = void 0, ...n) => {
  try {
    return ue(e, t, ...n).newDocument;
  } catch (r) {
    return R == null || R.logLvl(3, r, e, t), R == null || R.report(r), s;
  }
}, es = (e, t, s) => {
  if (e.op !== "add" || !e.path.endsWith("/-")) return !0;
  const n = e.path.split("/").slice(1, -1);
  let r = s;
  for (const o of n)
    r[o] === null && (r[o] = []), r = r[o];
  return !0;
}, ts = (e) => {
  const t = z(), { queryFn: s, queryKey: n, braidKey: r } = e, o = J({
    ...e,
    queryFn: typeof s == "function" ? async (...a) => {
      const i = await s(...a);
      return t.getQueryData(n) ?? i;
    } : void 0
  });
  return l(() => () => {
    ie.close(r);
  }, [r]), o;
}, ze = (e, t, s) => {
  t.setQueryData(s, e);
}, Je = (e, t, s) => {
  t.setQueryData(
    s,
    (n = V()) => je(ye(n), e) ?? n
  );
}, P = (e, t) => ({
  onFetched: (s) => ze(s, t, e),
  onPatch: (s) => Je(s, t, e)
}), ss = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1
}, $ = (e) => (t) => ({
  gcTime: t,
  staleTime: t,
  retry: 2,
  refetchOnMount: !0,
  refetchOnReconnect: e,
  refetchOnWindowFocus: e
}), u = {
  "7d": 6048e5,
  "3d": 2592e5,
  "1d": 864e5,
  "6h": 216e5,
  "1h": 36e5,
  "30m": 18e5,
  "15m": 9e5,
  "10m": 6e5,
  "5m": 3e5,
  "3m": 18e4,
  "1m": 6e4,
  "30s": 3e4,
  "15s": 15e3,
  "5s": 5e3,
  "3s": 3e3,
  "1s": 1e3
}, E = [""], A = () => {
}, g = $(!0), M = {
  "7d": g(u["7d"]),
  "3d": g(u["3d"]),
  "1d": g(u["1d"]),
  "6h": g(u["6h"]),
  "1h": g(u["1h"]),
  "30m": g(u["30m"]),
  "15m": g(u["15m"]),
  "10m": g(u["10m"]),
  "5m": g(u["5m"]),
  "3m": g(u["3m"]),
  "1m": g(u["1m"]),
  "30s": g(u["30s"]),
  "15s": g(u["15s"]),
  "3s": g(u["3s"]),
  0: g(0)
}, S = $(!1), C = {
  "7d": S(u["7d"]),
  "3d": S(u["3d"]),
  "1d": S(u["1d"]),
  "6h": S(u["6h"]),
  "1h": S(u["1h"]),
  "30m": S(u["30m"]),
  "15m": S(u["15m"]),
  "10m": S(u["10m"]),
  "5m": S(u["5m"]),
  "3m": S(u["3m"]),
  "1m": S(u["1m"]),
  "30s": S(u["30s"]),
  "15s": S(u["15s"]),
  "3s": S(u["3s"]),
  0: S(0)
}, O = (e = 0) => ({
  ...$(!1)(e),
  refetchOnReconnect: !0
}), ns = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: s,
  options: n,
  params: r
}) => {
  const o = !!e, a = o ? N(e, t) : E, i = `get-session-ai-feed-${e}-${t}`;
  return {
    ...O(0),
    ...n,
    queryKey: a,
    braidKey: i,
    enabled: o,
    queryFn: o ? ({ signal: y }) => L.getFeed(e, t, {
      ...P(a, s),
      ...r,
      key: i,
      signal: y,
      params: {
        limit: 200,
        sortOrder: "asc",
        ...r == null ? void 0 : r.params
      }
    }) : A
  };
}, rs = ({
  sessionId: e,
  recurrenceId: t,
  options: s,
  params: n
}) => ({
  ...s,
  mutationFn: (r) => L.event(e, t, {
    ...n,
    data: r
  })
}), os = ({
  sessionId: e,
  recurrenceId: t
}) => ({
  queryKey: we(e, t),
  queryFn: () => L.getSummary(e, t),
  ...C["5m"]
}), as = (e) => [Z, e], is = (e) => ({
  queryKey: [Z, e],
  queryFn: () => te.getCalndrLinkEvent({ data: e })
}), Xe = (e, t) => [
  ve,
  e,
  t
], us = ({
  sessionId: e,
  sessionRecurrenceId: t,
  queryClient: s,
  options: n
}) => {
  const r = !!e && !!t, o = r ? Xe(e, t) : E, a = `get-cms-cards-${e}-${t}`;
  return {
    ...O(0),
    queryKey: o,
    braidKey: a,
    enabled: r,
    queryFn: r ? () => d.cards(e, t, {
      ...P(o, s),
      key: a
    }) : A,
    ...n
  };
}, Ze = (e) => [Ce, e], ys = ({
  slug: e,
  isPreview: t
}) => ({
  queryKey: e ? Ze(e) : E,
  queryFn: () => W.getPage(e || ""),
  ...C[t ? 0 : "5m"],
  enabled: !!e
}), he = (e) => [pe, e], Es = ({
  types: e,
  isPreview: t
}) => ({
  queryKey: he(e),
  queryFn: async () => {
    var o;
    const s = await W.getPages(e);
    if (!((o = s == null ? void 0 : s.data) != null && o.result)) throw s;
    const r = s.data.result.reduce(
      (a, i) => i.type ? i.type === "general" ? (i.category && (a.general[i.category] = [...a[i.type][i.category] || [], i]), a) : (a[i.type] = [...a[i.type] || [], i], a) : a,
      {
        general: {
          // here because of the order
          features: [],
          solutions: [],
          compare: [],
          resources: [],
          product: [],
          none: []
        }
      }
    );
    return {
      ...s,
      grouped: r
    };
  },
  ...C[t ? 0 : "15m"],
  gcTime: t ? 0 : 864e5
  // 1 day
}), et = (e, t) => [Be, e, t], _s = ({
  sessionId: e,
  recurrenceId: t,
  queryClient: s,
  options: n,
  params: r
}) => {
  const o = !!e && !!t, a = o ? et(e, t) : E, i = `crmBindings-${e}-${t}`;
  return {
    queryKey: a,
    braidKey: i,
    enabled: o,
    ...O(0),
    ...n,
    queryFn: o ? () => Y.getBindingsByOwnerSessionRecurrencePerProvider(
      e,
      t,
      {
        key: i,
        ...P(a, s),
        ...r
      }
    ) : A
  };
}, gs = ({
  params: e,
  ...t
}) => ({
  ...t,
  mutationFn: ({
    sessionId: s,
    sessionRecurrenceId: n,
    record: { id: r, type: o }
  }) => Y.hubspot.createBinding({
    ...e,
    data: {
      sessionId: s,
      sessionRecurrenceId: n,
      record: {
        id: r,
        type: o
      }
    }
  })
}), tt = (e) => [Ie, e], Ss = ({
  id: e,
  params: t,
  ...s
}) => {
  const n = !!e;
  return {
    ...C["1m"],
    ...s,
    enabled: n,
    queryKey: n ? tt(e) : E,
    queryFn: n ? () => Y.hubspot.getCompanyById(e) : A
  };
}, st = [de], cs = {
  queryKey: st,
  queryFn: Y.getProviders,
  ...C["1h"]
}, ms = ({
  userId: e,
  options: t,
  params: s
}) => {
  const n = !!e, r = n ? U(e) : E;
  return {
    ...M["3m"],
    ...t,
    queryKey: r,
    queryFn: n ? () => H.threads(s) : A
  };
}, Cs = ({
  id: e,
  options: t,
  params: s
}) => {
  const n = !!e, r = n ? U(e) : E;
  return {
    ...C["3m"],
    ...t,
    queryKey: r,
    enabled: n,
    queryFn: n ? () => H.thread(e, s) : A
  };
}, ps = ({
  id: e,
  options: t,
  params: s,
  onFetch: n
}) => {
  const r = !!e, o = r ? T(e) : E;
  return {
    ...C["3m"],
    queryKey: o,
    enabled: r,
    ...t,
    queryFn: r ? () => (n == null || n(), H.threadMessages(e, s)) : A
  };
}, As = ({
  id: e,
  options: t,
  params: s
}) => ({
  ...t,
  mutationFn: (n) => H.deleteThread(e, {
    ...s,
    data: n
  })
}), nt = (e) => [
  De,
  e
], ls = (e) => ({
  queryKey: e ? nt(e) : E,
  queryFn: e ? () => j.getEpisodes(e) : A,
  ...C["5m"],
  enabled: !!e
}), rt = (e, t) => [
  Te,
  e,
  t
], ds = (e, t) => {
  const s = !!e && !!t;
  return {
    queryKey: s ? rt(e, t) : E,
    ...C["15m"],
    queryFn: s ? () => j.getRecording(e, t) : A,
    enabled: s
  };
}, Rs = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: s,
  options: n,
  params: r
}) => {
  const o = !!e, a = o ? I(e, t) : E, i = `get-session-pending-access-${e}-${t}`;
  return {
    ...O(),
    ...n,
    queryKey: a,
    braidKey: i,
    enabled: o,
    queryFn: ({ signal: y }) => D.inReview({
      ...P(a, s),
      ...r,
      signal: y,
      key: i,
      params: {
        ...r == null ? void 0 : r.params,
        sessionID: e || "",
        sessionRecurrenceID: t
      }
    })
  };
}, Os = ({
  userId: e,
  sessionId: t,
  recurrenceId: s = "",
  queryClient: n,
  options: r,
  params: o
}) => {
  const a = !!e && !!t, i = a ? Le(e, t, s) : E, y = `get-session-request-access-${t}-${s}-${e}`;
  return {
    ...O(),
    ...r,
    queryKey: i,
    braidKey: y,
    enabled: a,
    queryFn: a ? ({ signal: c }) => {
      const _ = { ...o == null ? void 0 : o.params, sessionID: t };
      return s && (_.sessionRecurrenceID = s), D.userSessionRequest({
        ...P(i, n),
        ...o,
        signal: c,
        key: y,
        params: _
      });
    } : A
  };
}, fs = ({
  options: e,
  params: t
} = {}) => ({
  ...e,
  mutationFn: (s) => D.requestAccess({
    ...t,
    data: s
  })
}), Ks = ({
  options: e,
  params: t
}) => ({
  ...e,
  mutationFn: (s) => D.update({
    ...t,
    data: s
  })
}), ot = (e, t) => [
  G,
  e,
  t,
  q.GRANTED
], Ts = (e, t, s) => ({
  queryKey: ot(e, t),
  queryFn: () => D.getViewer({
    params: {
      limit: 50,
      ...s,
      sessionID: e,
      sessionRecurrenceID: t,
      restrictionStatus: q.GRANTED
    }
  }),
  ...M["3m"]
}), Ps = ({
  sessionId: e,
  recurrenceId: t = "",
  userId: s,
  onFetched: n,
  onPatch: r,
  onError: o,
  options: a,
  params: i,
  queryClient: y
}) => {
  const c = !!e && !!s, _ = c ? ke(e, t, s) : E, p = `get-session-${e}-${t}-${s}`;
  return {
    ...O(),
    initialData: void 0,
    retry: 2,
    enabled: c,
    ...a,
    queryKey: _,
    braidKey: p,
    queryFn: c ? ({ signal: f }) => d.getById(e, {
      ...i,
      key: p,
      signal: f,
      onFetched: (K) => {
        y.setQueryData(_, K), n && n(K);
      },
      onPatch: r,
      onError: o
    }) : void 0
  };
}, qs = ({
  sessionId: e,
  recurrenceId: t = "",
  options: s,
  params: n
}) => ({
  ...s,
  mutationFn: (r) => d.update(e, t, {
    ...n,
    data: r
  })
}), Fs = ({
  sessionId: e
}) => ({
  mutationFn: () => d.end(e)
}), Ds = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: s,
  options: n,
  params: r
}) => {
  const o = e ? Ue(e, t) : E, a = `get-session-presence-${e}-${t}`;
  return {
    ...O(),
    enabled: !!e,
    ...n,
    queryKey: o,
    braidKey: a,
    queryFn: e ? ({ signal: i }) => d.presence(e, {
      ...P(o, s),
      ...r,
      signal: i,
      key: a
    }) : void 0
  };
}, Ms = ({
  sessionId: e,
  params: t,
  options: s
}) => ({
  ...s,
  mutationFn: (n) => d.updateParticipantMetadata(e, {
    ...t,
    data: n
  })
}), ee = 50, Q = (e) => [
  k,
  le,
  e
], vs = (e, {
  limit: t = ee
} = {}) => ({
  queryKey: e ? Q(e) : E,
  queryFn: async ({ pageParam: s = 0 }) => d.getSessionsLibraryUpcoming({
    params: {
      limit: t,
      cursor: s * t
    }
  }),
  initialPageParam: 0,
  ...M["15s"],
  getNextPageParam: (s, n) => n.length + 1,
  enabled: !!e
}), at = (e) => [
  k,
  Ae,
  e
], Ns = (e, {
  limit: t = ee
} = {}) => ({
  queryKey: e ? at(e) : E,
  queryFn: async ({ pageParam: s = 0 }) => d.getSessionsLibraryHistory({
    params: {
      limit: t,
      cursor: s * t
    }
  }),
  initialPageParam: 0,
  ...M["30s"],
  getNextPageParam: (s, n) => n.length + 1,
  enabled: !!e
}), Qs = ({
  userId: e,
  accessToken: t,
  params: s
}) => ({
  mutationFn: () => {
    if (!e || !t) throw new Error("Unauthorized");
    return b.activateTrial(e, {
      ...s,
      data: { token: t }
    });
  }
}), Ys = ({
  accessToken: e,
  params: t
}) => ({
  mutationFn: () => se.hostOptIn({
    data: { accessToken: e },
    ...t
  })
}), it = () => [Ye], Hs = () => ({
  queryKey: it(),
  queryFn: ne.getPlans,
  ...C["1h"]
}), ut = (e, t) => [
  Ne,
  e,
  t
], bs = (e, t) => {
  const s = !!e && !!t;
  return {
    queryKey: s ? ut(e, t) : E,
    queryFn: s ? () => re.getStream(t) : void 0,
    enabled: s,
    ...M["15m"]
  };
}, Bs = ({
  id: e,
  options: t,
  params: s
}) => {
  const n = $e(e);
  return {
    ...C["3m"],
    ...t,
    queryKey: n,
    queryFn: ({ signal: r }) => b.getById(e, { ...s, signal: r })
  };
}, yt = (e, t) => [
  He,
  e,
  t
], Gs = (e, t, s) => ({
  queryKey: yt(e, t),
  queryFn: () => b.getTransactions(e, {
    params: { includeInvoices: "true", ...s }
  }),
  ...C["3m"]
}), Et = (e) => [Ke, e], Is = (e, t) => {
  const s = !!e && !!t;
  return {
    queryKey: s ? Et(e) : E,
    queryFn: e ? () => b.getPaymentDetails(e || "") : A,
    ...C["3m"],
    enabled: s
  };
}, _t = () => [Ge], Ls = () => ({
  queryKey: _t(),
  queryFn: oe.get,
  ...C["1h"]
}), gt = {
  add: ({ client: e, data: t, userId: s }) => {
    t && m.request.addToPages({
      client: e,
      key: Q(s),
      updateFn: (n) => {
        const r = (n == null ? void 0 : n.sessions) || [];
        return {
          ...n,
          count: ((n == null ? void 0 : n.count) || r.length) + 1,
          sessions: [t, ...r]
        };
      }
    });
  },
  update: ({ client: e, data: t, userId: s }) => {
    t && m.request.updateInPages({
      client: e,
      key: Q(s),
      stopOnChange: !0,
      updateFn: (n) => {
        if (!(n != null && n.sessions)) return n;
        const r = n.sessions.findIndex((a) => a.sessionID === t.sessionID);
        if (r === -1) return n;
        const o = {
          ...n,
          sessions: [...n.sessions]
        };
        return o.sessions[r] = t, o;
      }
    });
  },
  remove: ({ client: e, userId: t, id: s }) => {
    let n;
    return m.request.updateInPages({
      client: e,
      key: Q(t),
      stopOnChange: !0,
      updateFn: (r) => {
        if (!(r != null && r.sessions)) return r;
        const o = r.sessions.findIndex((i) => i.sessionID === s);
        if (o === -1) return r;
        const a = { ...r, sessions: [...r.sessions] };
        return a.count = Math.max(0, (a.count || 1) - 1), n = a.sessions.splice(o, 1)[0], a;
      }
    }), n;
  }
}, St = {
  add: ({ client: e, data: t = [] }) => {
    if (!t.length) return;
    const { sessionID: s, sessionRecurrenceID: n } = t[0];
    m.request.update({
      client: e,
      key: [
        G,
        s,
        n,
        q.GRANTED
      ],
      dataOrFn: (r = {}) => {
        var p, f;
        const o = { ...r }, a = (K, B) => (B.type === "domain" ? K[0].push(B) : K[1].push(B), K), [i, y] = ((f = (p = o == null ? void 0 : o.data) == null ? void 0 : p.viewerAccessRules) == null ? void 0 : f.reduce(
          a,
          [[], []]
        )) || [], [c, _] = t.reduce(a, [
          [],
          []
        ]);
        return {
          data: {
            viewerAccessRules: [
              ...c,
              ...i || [],
              ..._,
              ...y || []
            ]
          }
        };
      }
    });
  },
  remove: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: s,
    id: n
  }) => {
    m.request.update({
      client: e,
      key: [
        G,
        t,
        s,
        q.GRANTED
      ],
      dataOrFn: (r) => {
        var a;
        const o = ((a = r == null ? void 0 : r.data.viewerAccessRules) == null ? void 0 : a.filter((i) => i.id !== n)) || [];
        return {
          ...r,
          data: {
            ...r == null ? void 0 : r.data,
            viewerAccessRules: o
          }
        };
      }
    });
  },
  // requests
  addRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: s,
    data: n
  }) => {
    var i;
    const r = I(t, s), o = e.getQueryData(r) || V(void 0), a = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((i = o.data) == null ? void 0 : i.accessRules) || [], ...n]
      }
    };
    e.setQueryData(r, a);
  },
  removeRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: s,
    id: n
  }) => {
    var _, p;
    const r = I(t, s), o = e.getQueryData(r);
    if (!((_ = o == null ? void 0 : o.data) != null && _.accessRules)) return;
    const a = o.data.accessRules, i = a == null ? void 0 : a.findIndex((f) => f.id === n);
    if (i === -1) return;
    const y = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((p = o.data) == null ? void 0 : p.accessRules) || []]
      }
    }, c = y.data.accessRules.splice(i, 1)[0];
    return e.setQueryData(r, y), c;
  }
}, ks = {
  session: gt,
  viewerAccess: St
}, Us = (e) => {
  const t = ae(e), { onSuccess: s, onError: n } = e, { isError: r, isSuccess: o, error: a, data: i } = t;
  return l(() => {
    o && s && s(i);
  }, [i, o, s]), l(() => {
    r && n && n(a);
  }, [a, r, n]), t;
}, $s = (e) => {
  const t = J(e), { onSuccess: s, onError: n } = e, { isError: r, isSuccess: o, error: a, data: i } = t;
  return l(() => {
    o && s && s(i);
  }, [i, o, s]), l(() => {
    r && n && n(a);
  }, [a, r, n]), t;
}, ct = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1,
  refetchInterval: !1,
  useErrorBoundary: !1,
  enabled: !1
}, ws = (e, t) => {
  const s = z(), n = Ee(
    () => ({
      queryKey: e,
      ...ct,
      ...t
    }),
    [t, e]
  ), r = _e(n), [o, a] = x(
    () => new w(s, n)
  ), [i, y] = x(
    o == null ? void 0 : o.getOptimisticResult(n)
  );
  l(() => {
    r.current = n;
  }, [n]), l(() => {
    if (!(e != null && e.length)) return;
    const _ = new w(s, r.current);
    a(_);
    const p = _.getOptimisticResult(r.current);
    y(p);
  }, [s, e]), l(() => {
    const _ = o.subscribe((p) => {
      y(p);
    });
    return () => {
      _();
    };
  }, [o]);
  const c = ge(() => {
    const _ = o.getOptimisticResult(r.current);
    y(_);
  }, [o]);
  return { ...i, refresh: c };
};
export {
  Nt as CACHE_KEY_BRANCH,
  Z as CACHE_KEY_CALENDAR_EVENT,
  Ce as CACHE_KEY_CMS_PAGE,
  pe as CACHE_KEY_CMS_PAGES,
  Rt as CACHE_KEY_CURRENT_USER,
  Ae as CACHE_KEY_DASHBOARD_PAST_SESSIONS,
  le as CACHE_KEY_DASHBOARD_SESSIONS,
  Ot as CACHE_KEY_FEATURED_SESSIONS,
  kt as CACHE_KEY_HUBSPOT_GET_BINDING_BY_ID,
  Ie as CACHE_KEY_HUBSPOT_GET_COMPANY_BY_ID,
  wt as CACHE_KEY_HUBSPOT_GET_CONTACTS,
  xt as CACHE_KEY_HUBSPOT_GET_CONTACT_BY_ID,
  Ut as CACHE_KEY_HUBSPOT_GET_DEAL_BY_ID,
  $t as CACHE_KEY_HUBSPOT_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  Lt as CACHE_KEY_HUBSPOT_PROVIDER,
  ft as CACHE_KEY_INTEGRATIONS_CONNECTIONS,
  Kt as CACHE_KEY_INTEGRATIONS_DIRECT_URL,
  Tt as CACHE_KEY_INTEGRATIONS_GET_ACTION,
  Be as CACHE_KEY_INTEGRATIONS_GET_BY_OWNER_SESSION_RECURRENCE,
  de as CACHE_KEY_INTEGRATIONS_PROVIDERS,
  Qe as CACHE_KEY_LISTENING_MODE_SPEAKERS,
  Re as CACHE_KEY_MEETING_MEMORY_MESSAGES,
  Pt as CACHE_KEY_MEETING_MEMORY_SUGGESTIONS,
  Oe as CACHE_KEY_MEETING_MEMORY_THREAD,
  fe as CACHE_KEY_MEETING_MEMORY_THREADS,
  Ke as CACHE_KEY_PAYMENT_DETAILS,
  k as CACHE_KEY_PRIVATE_BASE,
  Te as CACHE_KEY_RECORDING,
  qt as CACHE_KEY_RECORDING_STATUS,
  Ht as CACHE_KEY_SALESFORCE_GET_ACCOUNT_BY_ID,
  Yt as CACHE_KEY_SALESFORCE_GET_BINDING_BY_ID,
  Gt as CACHE_KEY_SALESFORCE_GET_CONTACTS,
  It as CACHE_KEY_SALESFORCE_GET_LEAD_BY_ID,
  bt as CACHE_KEY_SALESFORCE_GET_OPPORTUNITY_BY_ID,
  Bt as CACHE_KEY_SALESFORCE_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  Qt as CACHE_KEY_SALESFORCE_PROVIDER,
  Ft as CACHE_KEY_SESSION,
  Dt as CACHE_KEY_SESSION_ACCESS_RULE,
  G as CACHE_KEY_SESSION_ACCESS_RULES,
  qe as CACHE_KEY_SESSION_AI_FEED,
  Fe as CACHE_KEY_SESSION_END_SUMMARY,
  De as CACHE_KEY_SESSION_EPISODES,
  ve as CACHE_KEY_SESSION_GET_CARDS,
  Mt as CACHE_KEY_SESSION_PENDING_RULES_STATS,
  Me as CACHE_KEY_SESSION_PRESENCE,
  h as CACHE_KEY_SESSION_REQUEST_ACCESS,
  Pe as CACHE_KEY_SESSION_V2,
  vt as CACHE_KEY_SESSION_VIEWERS,
  Ne as CACHE_KEY_STREAM_TOKEN,
  Ye as CACHE_KEY_SUBSCRIPTION_PLANS,
  He as CACHE_KEY_SUBSCRIPTION_RECEIPTS,
  be as CACHE_KEY_USER,
  Ge as CACHE_KEY_USER_MEETING_TYPES,
  Qs as activateTrialMutation,
  M as activeOptions,
  zt as aiFeedCacheService,
  rs as aiFeedEventMutation,
  O as baseBraidOptions,
  Vt as buildPrivateQueryKey,
  m as cacheService,
  is as calendarEventQuery,
  ys as cmsPageQuery,
  Es as cmsPagesQuery,
  gs as createCRMBindingMutation,
  ze as defaultBraidOnFetched,
  Je as defaultBraidOnPatch,
  ss as disabledFetchOptions,
  E as emptyCacheKey,
  A as emptyFn,
  Fs as endSessionMutation,
  g as getActiveOptions,
  N as getAiFeedQueryKey,
  _s as getCRMProviderBindingsForSessionQuery,
  et as getCRMProviderBindingsForSessionQueryKey,
  as as getCalendarEventQueryKey,
  us as getCardsQuery,
  Xe as getCardsQueryKey,
  Ze as getCmsPageQueryKey,
  he as getCmsPagesQueryKey,
  P as getDefaultBraidCallbacks,
  S as getInactiveOptions,
  ht as getInfinityRequestData,
  st as getIntegrationsProvidersQueryKey,
  jt as getListeningModeSpeakersQueryKey,
  T as getMeetingMemoryThreadMessagesQueryKey,
  Wt as getMeetingMemoryThreadQueryKey,
  U as getMeetingMemoryThreadsQueryKey,
  at as getPastSessionQueryKey,
  Et as getPaymentDetailsQueryKey,
  Hs as getPlansQuery,
  it as getPlansQueryKey,
  Zt as getQueryRequestData,
  nt as getRecordingEpisodesQueryKey,
  rt as getRecordingQueryKey,
  We as getRequestData,
  ns as getSessionAiFeedQuery,
  we as getSessionEndSummaryQueryKey,
  ot as getSessionGrantedAccessRulesQueryKey,
  Ue as getSessionPresenceKey,
  Ps as getSessionQuery,
  ke as getSessionQueryKey,
  ut as getStreamTokenQueryKey,
  Q as getUpcomingSessionQueryKey,
  _t as getUserMeetingTypesQueryKey,
  $e as getUserQueryKey,
  yt as getUserTransactionsQueryKey,
  Ys as hostOptInMutation,
  tt as hubspotGetCompanyQueryKey,
  Ss as hubstopGetCompanyQuery,
  C as inactiveOptions,
  Xt as initConfig,
  cs as integrationsProvidersQuery,
  je as jsonPatch,
  es as jsonPatchArrayFallbackValidator,
  R as logger,
  Jt as meetingMemoryCacheService,
  As as meetingMemoryDeleteThreadMutation,
  ps as meetingMemoryThreadMessagesQuery,
  Cs as meetingMemoryThreadQuery,
  ms as meetingMemoryThreadsQuery,
  u as milliseconds,
  Ns as pastSessionQuery,
  Is as paymentDetailsQuery,
  ct as queryDataDefaultOptions,
  ls as recordingEpisodesQuery,
  ds as recordingQuery,
  fs as requestAccessMutation,
  ks as sessionCacheService,
  os as sessionEndSummaryQuery,
  Ts as sessionGrantedAccessRulesQuery,
  Rs as sessionPendingAccessQuery,
  I as sessionPendingAccessQueryKey,
  Ds as sessionPresenceQuery,
  bs as streamTokenQuery,
  vs as upcomingSessionQuery,
  Ms as updateParticipantMetadataMutation,
  Ks as updateSessionAccessMutation,
  qs as updateSessionMutation,
  ts as useBraid,
  Us as useInfiniteQuery,
  $s as useQuery,
  ws as useQueryData,
  Ls as userMeetingTypesQuery,
  Bs as userQuery,
  Os as userSessionRequestAccessQuery,
  Le as userSessionRequestAccessQueryKey,
  Gs as userTransactionsQuery
};
