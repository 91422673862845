import { braidService as w } from "@waitroom/braid";
import { braidService as ce } from "@waitroom/braid";
import { bearerValue as S, httpService as a } from "@waitroom/http-client";
import { httpService as oe } from "@waitroom/http-client";
let n;
const B = (e) => {
  n = {
    ...n,
    ...e
  };
}, L = (e) => {
  B(e);
}, f = async (e) => typeof e == "function" ? await e() : await e, R = async () => {
  const e = await f(n.getAuthToken);
  return {
    Authorization: e ? S(e) : void 0
  };
}, C = async () => {
  const [e, t] = await Promise.allSettled([
    f(n.getFingerPrint),
    f(n.getClientDetails)
  ]);
  return {
    "X-Fingerprint": e.status === "fulfilled" ? e.value : "",
    "X-Client-Info": JSON.stringify(t.status === "fulfilled" ? t.value : {}),
    "Accept-Language": "en"
    // TODO: This should be updated to pick the app's language
  };
}, k = (e, t) => `${e || n.baseURL}/${t || n.version}`, T = async (e, t) => ({
  ...await C(),
  // add auth header if non provided
  ...e && !(t != null && t.Authorization) ? await R() : {},
  ...t
}), A = (e) => async (t, { version: i, baseURL: c, headers: y, ...$ } = {}) => ({
  url: t,
  baseURL: k(c, i),
  headers: await T(e, y),
  ...$
}), r = A(!1), s = A(!0), F = {
  getFeed: async (e, t, i) => w.fetch(
    await s("/ai-feed/get", {
      ...n.defaultBraidParams,
      ...i,
      params: {
        ...i == null ? void 0 : i.params,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  event: async (e, t, i) => a.post(
    await s("/ai-feed/event", {
      ...n.defaultBraidParams,
      ...i,
      data: {
        ...i == null ? void 0 : i.data,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  updateFeed: async (e, t) => a.patch(await s(`/ai-feed/${e}`, t)),
  getSummary: async (e, t, i) => a.get(
    await s(`/post-session-summaries/by-session-ids/${e}`, {
      ...i,
      params: {
        formats: "md",
        ...i == null ? void 0 : i.params,
        sessionRecurrenceID: t
      }
    })
  ),
  internalCopyAIFeed: async (e) => a.post(await s("/ai-feed/copy", e))
}, D = "/auth", E = {
  verifyOTP: async (e) => a.post(await r("/auth/verify-otp", e)),
  verifyOAuthOTP: async (e) => a.post(await r("/oauth/verify-otp", e)),
  requestOTP: async (e) => a.post(
    await r("/auth/request-otp", {
      ...e,
      data: {
        ...e.data,
        fingerprint: await n.getFingerPrint
      }
    })
  ),
  requestOAuthOTP: async (e) => a.post(
    await r("/oauth/request-otp", {
      ...e,
      data: {
        ...e.data,
        fingerprint: await n.getFingerPrint
      }
    })
  ),
  verifyAuthTokenProvider: async (e) => a.post(await r("/auth/social-token", e)),
  verifyOAuthTokenProvider: async (e) => a.post(await r("/oauth/social-token", e)),
  verifyAuthCodeProvider: async (e) => a.post(await r("/auth/social-code", e)),
  verifyOAuthCodeProvider: async (e) => a.post(await r("/oauth/social-code", e)),
  refreshToken: async (e) => a.put(await r("/auth/refresh-auth-token", e)),
  logout: async (e) => a.post(await s("/auth/logout", e)),
  hostOptIn: async (e) => a.patch(await s("/auth/user/host-opt-in", e)),
  user: async (e) => a.get(await s("/auth/user", e)),
  registerGuest: async (e) => a.post(await s("/auth/guest", e))
}, z = {
  getCalndrLinkEvent: async (e) => a.post({
    url: "https://calndr.link/api/events",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...e.headers
    },
    ...e
  })
}, O = `
  _id,
  type,
  hero,
  cta,
  vertical,
  horizontal,
  grid,
  testimonials,
  blockList,
  features,
  pricing,
  faq,
  compareTable
`, P = `
  _id,
  title,
  description,
  slug,
  url,
  image,
  category,
  type,
  menuTitle,
  menuIcon,
  menuOrder,
  hideHeaderMenu
`, I = `
  ${P},
  sections
`, M = {
  getPage: async (e, t) => {
    var c, y;
    const i = await a.get({
      ...t,
      url: n.sanityUrl,
      params: {
        ...t == null ? void 0 : t.params,
        query: `*[_type == "page" && slug.current == "${e}"]{
          ${I},
          sections[] {
            ...,
            section->{
              ${O}
            }
          }
        }`
      },
      throwError: !1,
      withCredentials: !0
    });
    if (((y = (c = i.data) == null ? void 0 : c.result) == null ? void 0 : y.length) === 0) throw Error("Nothing found");
    return i;
  },
  getPages: async (e, t) => a.get({
    ...t,
    url: n.sanityUrl,
    params: {
      ...t == null ? void 0 : t.params,
      query: `*[_type == "page"${e != null && e.length ? ` && (${e.map((i) => `type == "${i}"`).join(" || ")})` : ""}]{${P}}|order(category desc, menuOrder asc)`
    },
    throwError: !1,
    withCredentials: !0
  })
}, V = {
  getPresignedUrl: async (e) => a.get(await s("/image-uploads/presigned-url", e))
}, d = "/integrations", g = `${d}/salesforce`, l = `${d}/hubspot`, _ = {
  getProviders: async (e) => a.get(await r(`${d}/providers`, e)),
  getConnections: async (e) => a.get(await s(`${d}/connections`, e)),
  getAction: async (e, t, i) => a.get(await s(`${d}/${e}/${t}`, i)),
  callAction: async (e, t, i) => a.post(await s(`${d}/${e}/${t}`, i)),
  getFromAbsoluteURL: async (e, t) => a.get(await s("", { url: e, ...t })),
  postToAbsoluteURL: async (e, t) => a.post(await s("", { url: e, ...t })),
  absoluteURL: async (e, t, i) => a.request(await s("", { url: t, method: e, ...i })),
  authorize: async (e) => a.get(await s(`${d}/authorize`, e)),
  disconnect: async (e) => a.delete(await s(`${d}/disconnect`, e)),
  getBindingsByOwnerSessionRecurrencePerProvider: async (e, t, i) => w.fetch(
    await s(
      `${d}/-/get-bindings-by-owner-session-recurrence-per-provider`,
      {
        ...i,
        ...n.defaultBraidParams,
        params: {
          sessionId: e,
          sessionRecurrenceId: t
        }
      }
    )
  ),
  // Salesforce
  salesforce: {
    createBinding: async (e) => a.post(
      await s(`${g}/bindings`, e)
    ),
    deleteBinding: async (e, t) => a.delete(
      await s(`${g}/bindings/${e}`, t)
    ),
    getAccountById: async (e, t) => a.get(
      await s(`${g}/accounts/${e}`, t)
    ),
    getOpportunityById: async (e, t) => a.get(
      await s(`${g}/opportunities/${e}`, t)
    ),
    listBindableRecordsAsOptions: async (e) => a.get(
      await s(
        `${g}/-/list-bindable-records-as-options`,
        e
      )
    ),
    createContact: async (e, t) => a.post(
      await s(`${g}/contacts`, {
        data: e,
        ...t
      })
    ),
    getContacts: async (e, t, i) => a.get(
      await s(`${g}/contacts/-/list-related-to-record`, {
        params: {
          ...i == null ? void 0 : i.params,
          recordId: e,
          recordType: t
        },
        ...i
      })
    ),
    getLeadById: async (e, t) => a.get(
      await s(`${g}/leads/${e}`, t)
    )
  },
  // Hubspot
  hubspot: {
    createBinding: async (e) => a.post(
      await s(`${l}/bindings`, e)
    ),
    deleteBinding: async (e, t) => a.delete(
      await s(`${l}/bindings/${e}`, t)
    ),
    getCompanyById: async (e, t) => a.get(
      await s(`${l}/companies/${e}`, t)
    ),
    getDealById: async (e, t) => a.get(
      await s(`${l}/deals/${e}`, t)
    ),
    listBindableRecordsAsOptions: async (e) => a.get(
      await s(
        `${l}/-/list-bindable-records-as-options`,
        e
      )
    ),
    getContacts: async (e, t, i) => a.get(
      await s(`${l}/contacts/-/list-related-to-record`, {
        params: {
          ...i == null ? void 0 : i.params,
          recordId: e,
          recordType: t
        },
        ...i
      })
    ),
    getContactById: async (e, t) => a.get(
      await s(`${l}/contacts/${e}`, t)
    )
  }
}, u = "/memory", j = {
  ai: async (e, t, i) => {
    const c = await s(`${u}/ai-stream`, t);
    return n.fetchEventSource(`${c.baseURL}${c.url}`, {
      method: "POST",
      body: JSON.stringify(c.data),
      headers: c.headers,
      ...i,
      onmessage: e
    });
  },
  thread: async (e, t) => a.get(await s(`${u}/threads/${e}`, t)),
  threadMessages: async (e, t) => a.get(await s(`${u}/threads/${e}/messages`, t)),
  threads: async (e) => a.get(await s(`${u}/threads`, e)),
  deleteThread: async (e, t) => a.delete(await s(`${u}/threads/${e}`, t)),
  stop: async (e) => a.post(await s(`${u}/stop`, e)),
  feedback: async (e) => a.patch(await s(`${u}/feedback`, e)),
  suggestions: async (e) => a.get(await s(`${u}/suggestions`, e))
}, x = {
  // Used to let a user connect their google calendar to recall
  getGoogleAuthURL: async () => {
    var t;
    return ((t = (await a.get(
      await s("/transcriptions/bot/google-auth")
    )).data) == null ? void 0 : t.authURL) ?? "";
  },
  // ATTN: Only google is supported for now:
  disconnectCalendar: async (e) => {
    await a.post(
      await s("/transcriptions/bot/disconnect", {
        data: {
          platform: e
        }
      })
    );
  },
  getCalendarUser: async () => (await a.get(
    await s("/transcriptions/bot/calendar-user")
  )).data ?? null
}, G = {
  getStatus: async (e, t, i) => a.get(
    await s(`/recordings/${e}/recurrence/${t}/status`, {
      ...i,
      version: "v2.0"
    })
  ),
  getRecording: async (e, t, i) => a.get(
    await s(
      `/recordings/${e}/recurrence/${t}/recording`,
      { ...i, version: "v2.0" }
    )
  ),
  getPeople: async (e, t, i) => a.get(
    await s(`/recordings/${e}/recurrence/${t}/people`, {
      ...i,
      version: "v2.0"
    })
  ),
  getEpisodes: async (e, t) => a.get(
    await s(`/recordings/${e}/episodes`, {
      ...t,
      version: "v2.0"
    })
  ),
  getSessionRecordings: async (e, t, i) => a.get(
    await s(`/recordings/by-session/${e}/${t}`, i)
  ),
  getLatestPlayable: async (e) => a.get(await s("/recordings/latest-playable", e))
}, H = {
  getById: async (e, t) => w.fetch(
    await s(`/sessions/get/${e}`, {
      ...n.defaultBraidParams,
      ...t,
      version: "v2.0"
    })
  ),
  getFeatured: async (e) => a.get(await r("/sessions/featured", e)),
  subscribe: async (e) => a.post(await s("/sessions/subscribe", e)),
  unsubscribe: async (e) => a.post(await s("/sessions/unsubscribe", e)),
  getCategories: async (e) => a.get(await s("/sessions/category", e)),
  create: async (e) => a.post(await s("/sessions", e)),
  update: async (e, t, i) => a.patch(
    await s(`/sessions/${e}/recurrence/${t}`, i)
  ),
  end: async (e) => a.post(await s(`/meeting/${e}/end`)),
  delete: async (e, t, i) => a.patch(
    await s(`sessions/${e}/recurrence/${t}`, i)
  ),
  getPast: async (e) => a.get(await s("/sessions/get-past-sessions", e)),
  getFuture: async (e) => a.get(await s("/sessions/get-future-sessions", e)),
  restartSession: async (e, t, i) => a.patch(
    await s(`/sessions/${e}/recurrence/${t}/recur`, i)
  ),
  getSessionsLibraryHistory: async (e) => a.get(await s("/sessions/library/history", e)),
  getSessionsLibraryUpcoming: async (e) => a.get(await s("/sessions/library/upcoming", e)),
  presence: async (e, t) => w.fetch(
    await s(`/sessions/presence/${e}`, {
      ...n.defaultBraidParams,
      ...t
    })
  ),
  updateParticipantMetadata: async (e, t) => a.patch(await s(`meeting/${e}/participant`, t)),
  registerGuest: async (e) => a.post(await s("/sessions/guest", e)),
  cards: async (e, t, i) => w.fetch(
    await s("/sessions/cards", {
      params: {
        sessionId: e,
        sessionRecurrenceId: t
      },
      ...n.defaultBraidParams,
      ...i
    })
  )
}, o = "/sessions/access", N = {
  inReview: async (e) => w.fetch(
    await s(`${o}/in-review`, {
      ...n.defaultBraidParams,
      ...e
    })
  ),
  userSessionRequest: async (e) => w.fetch(
    await s(`${o}/get`, {
      ...n.defaultBraidParams,
      ...e
    })
  ),
  getViewer: async (e) => a.get(await s(`${o}/viewer`, e)),
  getViewerAccessStats: async (e) => a.get(await s(`${o}/get-viewer-access-stats`, e)),
  getInReviewViewerAccessStats: async (e) => a.post(
    await s(`${o}/get-in-review-access-requests-count`, e)
  ),
  requestAccess: async (e) => a.post(await s(`${o}/request-access`, e)),
  update: async (e) => a.put(await s(`${o}/request`, e)),
  add: async (e) => a.put(await s(`${o}/add`, e)),
  remove: async (e) => a.delete(await s(`${o}/remove`, e))
}, b = "/session-recordings/off-the-record", J = {
  enableOffTheRecord: async (e) => a.post(
    await s(`${b}/enable`, {
      version: "v2.0",
      ...e
    })
  ),
  disableOffTheRecord: async (e) => a.post(
    await s(`${b}/disable`, {
      version: "v2.0",
      ...e
    })
  )
}, X = {
  getPlans: async (e) => a.get(await r("/billing/plans", { ...e })),
  getPlan: async (e, t) => a.get(
    await r(`/billing/plans/${e}`, {
      ...t
    })
  )
}, W = {
  getByUserId: async (e, t, i, c = "audience") => a.post(
    await s("/agora/token", {
      data: {
        userId: t,
        sessionId: e,
        role: c,
        expirationTs: i,
        tokenType: "userAccount"
      }
    })
  ),
  getByUid: async (e, t, i, c = "audience") => a.post(
    await s("/agora/token", {
      data: {
        sessionId: e,
        uid: t,
        role: c,
        expirationTs: i,
        tokenType: "uid"
      }
    })
  ),
  getChat: async (e, t, i) => a.get(
    await s("/chat/token", {
      ...i,
      params: {
        ...i == null ? void 0 : i.params,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  getStream: async (e, t) => a.post(await s(`/meeting/${e}/token`, t))
}, m = "/transcriptions/", K = {
  getHeardSpeakers: async (e) => a.get(await s(`${m}speakers`, e)),
  identifyHeardSpeakers: async (e) => a.post(await s(`${m}speakers`, e))
}, Q = {
  getById: async (e, t) => a.get(await s(`/users/id/${e}`, t)),
  getCurrent: async (e) => a.get(await s("/users/me", e)),
  update: async (e, { data: t, ...i }) => a.put(
    await s(`/users/id/${e}`, {
      ...i,
      data: {
        userData: t
      }
    })
  ),
  getSubscriptionPlan: async (e, t) => a.get(
    await s(`/users/${e}/plan`, {
      ...t
    })
  ),
  updateSubscriptionPlan: async (e, t) => a.patch(
    await s(`/users/${e}/plan`, {
      ...t
    })
  ),
  reactivateSubscriptionPlan: async (e, t) => a.post(
    await s(`/users/${e}/plan/reactivate`, {
      ...t
    })
  ),
  cancelSubscriptionPlan: async (e, t) => a.post(
    await s(`/users/${e}/plan/cancel`, {
      ...t
    })
  ),
  updatePaymentTransaction: async (e, t) => a.get(
    await s(`/users/${e}/update-payment-method-transaction`, {
      ...t
    })
  ),
  activateTrial: async (e, t) => a.post(
    await s(`/users/${e}/activate-trial`, {
      ...t
    })
  ),
  createCustomer: async (e, t) => a.post(
    await s(`/users/${e}/customer`, {
      ...t
    })
  ),
  getTransactions: async (e, t) => a.get(
    await s(`/users/${e}/transactions`, {
      ...t
    })
  ),
  getTransactionInvoice: async (e, t, i) => a.get(
    await s(`/users/${t}/transactions/${e}/invoice`, { ...i })
  ),
  getPaymentDetails: async (e, t) => a.get(
    await s(`/users/${e}/payment-method-details`, {
      ...t
    })
  ),
  requestDelete: async () => a.delete(await s("/users/me"))
}, Y = {
  get: async (e) => a.get(await s("/meeting-types", e))
}, Z = (e) => e == null ? void 0 : e.data, ee = (e) => {
  var t;
  return (t = e == null ? void 0 : e.data) == null ? void 0 : t.data;
}, te = (e) => {
  var y, $;
  if (!e) return;
  let t, i, c = !1;
  if ("headers" in e && "error" in e) {
    const { errCode: h, statusCode: p, message: v } = e.error || {};
    t = h || p || e.code || ((y = e.data) == null ? void 0 : y.code), i = (($ = e.data) == null ? void 0 : $.message) || e.message || v;
  } else {
    let {
      code: h,
      message: p,
      success: v
    } = "data" in e ? (e == null ? void 0 : e.data) || {} : e || {};
    "message" in e && p === void 0 && ({ code: h, message: p, success: v } = e), t = h, i = p, c = !!v;
  }
  return {
    code: t,
    message: i,
    success: c
  };
}, ae = (e) => ({
  success: !0,
  code: 200,
  data: e
}), se = (e) => ({
  success: !0,
  code: 200,
  data: {
    success: !0,
    code: 200,
    data: e
  }
});
export {
  F as aiApiService,
  E as authApiService,
  D as authBaseEndpoint,
  ce as braidService,
  r as buildApiParams,
  s as buildApiParamsWithAuth,
  T as buildHeaders,
  se as buildSuccessApiRequestResponse,
  ae as buildSuccessRequestResponse,
  z as calendarApiService,
  M as cmsApiService,
  n as config,
  Z as getApiData,
  ee as getApiResponseData,
  R as getAuthHeader,
  k as getBaseURL,
  te as getResponse,
  oe as httpService,
  V as imageApiService,
  L as initCommonApi,
  _ as integrationsApiService,
  j as meetingMemoryApiService,
  x as recallCalendarService,
  G as recordingApiService,
  N as sessionAccessApiService,
  H as sessionApiService,
  J as sessionRecordingsApiService,
  X as subscriptionPlanApiService,
  W as tokenApiService,
  K as transcriptionsApiService,
  B as updateConfig,
  Q as userApiService,
  Y as userMeetingTypesService
};
